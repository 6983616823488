import React from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import { CookiesPoup } from "./homepage";

export default function TermsAndCondtions() {
  return (
    <>
      <TitleAndMetaTags
        title="Cookies Policy | Workativ.com - Ensuring Your Privacy"
        description="Discover how Workatv.com uses cookies to enhance your browsing experience. Read our Cookies Policy to learn about the data we collect and how we protect your privacy."
        keywords={["workativ terms and conditions"]}
        ogTitle="Cookies Policy | Workativ.com - Ensuring Your Privacy"
        ogDescription="Discover how Workatv.com uses cookies to enhance your browsing experience. Read our Cookies Policy to learn about the data we collect and how we protect your privacy."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <section className="terms_condtions">
            <div className="space-explorations">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 space-explorations-content">
                    <h1>Cookie Policy</h1>
                  </div>
                </div>
              </div>
            </div>
            <TermsContent />
            {/* <RequestForm isFooterForm={true} /> */}
          </section>
          {/* <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function TermsContent() {
  return (
    <>
      <section className="statistics-boggle privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <p>
                The Cookies Notice explains how Workativ Software Solutions LLC
                (“XYZ”, “we”, “us”, “our”) uses cookies and similar technologies
                in the course of our business, through our website 
                <a href="https://workativ.com/">
                  Workativ Software Solutions LLC.
                </a>{" "}
                It explains what these technologies are, and why we use them, as
                well as your rights to control our use of them.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>1. WHAT IS A COOKIE? </h3>
              <p>
                Cookies are small text files that a website asks the user’s
                browser to save on the user’s system/device, when a user visits
                the website, to remember the user’s device upon revisiting the
                website or during the session. Cookies set up by the domain 
                <a href="https://workativ.com/">
                  Workativ Software Solutions LLC,
                </a>{" "}
                are called first-party cookies and cookies set by other
                domain(s) than the website(s) you are visiting are termed
                as third-party cookies. Third-party cookies are generally used
                for marketing and advertising purposes. 
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content ">
              <h3>3. PURPOSE OF USING COOKIES? </h3>

              <p>
                Our website <a href="https://workativ.com/">Workativ Software Solutions LLC</a> (“Workativ Software
                Solutions LLC” or “We” or “Our” or “Website”) uses cookies and
                other tracking technologies for the following purposes: 
              </p>
              <div className="alpha_letters">
                <ol>
                  <li>To analyze your use of our website  </li>
                  <li>For promotional and marketing purposes </li>
                  <li>
                    To understand your preferences such as preferred language   
                  </li>
                  <li>To deliver information that matches your interests</li>
                  <li>
                    To help us understand our audience and traffic patterns{" "}
                  </li>
                  <li>
                    To let you automatically log into programs and parts of our
                    site that require membership
                  </li>
                  <li>
                    To manage and present the information displayed on our
                    website
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>
                3. TYPES OF COOKIES USED BY Workativ Software Solutions LLC 
              </h3>
              <div className="alpha_letters">
              <ol>
                <li>Strictly Necessary Cookies </li>
                <li>Targeting Cookies </li>
                <li>Functionality Cookies</li>
                <li>Performance Cookies</li>
              </ol>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>4 .COOKIE LIST</h3>
              <p>
                The table below provides additional details on the specific
                cookies used on <a href="https://workativ.com/">Workativ Software Solutions LLC:</a> 
              </p>
              <p>
                <b>Strictly Necessary Cookies-</b> Some cookies are required for
                technical reasons in order for our websites to support functions
                such as logging in, and payment transactions.{" "}
              </p>
              <p>
                <b>Targeted Cookies-</b> These cookies may be set through our site by
                our advertising partners. They may be used by those companies to
                build a profile of your interests and show you relevant adverts
                on other sites. They do not store direct personal information
                but are based on uniquely identifying your browser and internet
                device.{" "}
              </p>
              <p>
                <b>Functionality Cookies-</b> Functionality cookies enable us to
                provide additional functions to you on our site such as
                personalization and remembering your saved preferences.{" "}
              </p>
              <p>
               <b>Performance Cookies-</b> Performance cookies are cookies used
                specifically for gathering data on how visitors use a website,
                which pages of a website are visited most often, or if they get
                error messages on web pages. These cookies monitor only the
                performance of the site as the user interacts with it. These
                cookies don’t collect identifiable information on visitors,
                which means all the data collected is anonymous and only used to
                improve the functionality of a website.{" "}
              </p>
              <p>
                Any cookie, whose purpose has not been identified by us or is
                unknown to our current understanding, is classified together
                as “Unclassified Cookies”. Such cookies may or may not adhere to
                our policy and we cannot take ownership of said cookies,
                however, we would periodically and actively try to investigate
                them and categorize them in their respective cookie categories
                for the benefit of our users, partners and customers.{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>5. Other tracking technologies like web beacons </h3>
              <p>
                Cookies are not the only way to recognize or track visitors on a
                website. We may use other similar technologies from time to
                time, like web beacons (sometimes called “tracking pixels” or
                “clear gifs”). These are tiny graphics files that contain a
                unique identifier that enables us to recognize when someone has
                visited our websites or opened an e-mail including these. In
                many instances, these technologies are reliant on cookies to
                function properly, and hence, declining cookies will impair
                their functioning.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>6.How can I control the cookie preferences?  </h3>
              <p>
                Most browsers are set to accept cookies by default. However, you
                may remove or reject cookies in your browser’s settings.
                Removing or rejecting the cookies may affect the performance of
                the website.  You may opt-out of the DoubleClick cookie by
                visiting the Google advertising opt-out page:
                <a href="http://www.google.com/policies/privacy/ads/">
                   http://www.google.com/policies/privacy/ads/.
                </a>{" "}
                Or you may opt-out of Google Analytics by visiting the Google
                Analytics Opt-out page:
                <a href=" http://tools.google.com/dlpage/gaoptout">
                   http://tools.google.com/dlpage/gaoptout.
                </a>
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 statistics-boggle-content">
              <h3>7. Update and Review </h3>
              <p>
                We may update this Cookie Policy from time to time, therefore,
                we encourage you to periodically review this page for the latest
                information on the Policy. 
              </p>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
